.tabs-theme-color
    display: flex
    .tab-link
        display: block
        background-color: #ECECEC
        color: #4D4F5C
        margin: 0 0.25rem
        padding: 0.5rem 1.25rem
        border-radius: 30px
        transition: all 250ms
        text-decoration: none !important
        &:hover
            background-color: darken(#ECECEC,5%)
        &.active
            background-color: $theme-color
            color: white